import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Footer from "../components/Footer"

const IndexPage = ({data}) => (
  <>
  <Layout>
    <Seo title="Revive autod" />
    <section id="carsofsale">
    <div className="product-album py-4 py-lg-5">
      <div className="container">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 g-md-5">
            {data.allCarsJson.nodes.map(post => (
            <div className="col" key={post.id}>
                <div className="card">
                <Link to={`/car/${post.id}/`}>
                <img className="card-img-top" src={post.image} alt="" width="560px"/>
                </Link>
                    <div className="card-body">
                      <Link className="card-tile" to={`../car/${post.id}/`}><h2>{post.title}</h2></Link>
                      <span className="card-price">{post.price}</span>
                    </div>
              </div>
            </div>
            ))}
          </div>
        </div>
        <div className="row row-cols-1 pt-lg-5 pt-2">
             <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <Link to="../sold-cars">
              <button type="button" className="btn btn-primary">Müüdud autod
               <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M24 7.99803C23.9895 7.72991 23.8791 7.42 23.6953 7.22455L17.6953 0.849259C17.2887 0.483896 16.5091 0.333072 16.0547 0.755509C15.6071 1.17157 15.6204 1.97849 16.0663 2.3962L20.285 6.87299H1.125C0.503699 6.87299 0 7.3767 0 7.99803C0 8.61937 0.503699 9.12308 1.125 9.12308H20.285L16.0663 13.5999C15.6824 13.9844 15.6115 14.8197 16.0547 15.2406C16.4978 15.6613 17.3054 15.5269 17.6953 15.1468L23.6953 8.77152C23.9006 8.55334 24.0007 8.29736 24 7.99803Z" fill="#FFC46C"/>
              </svg>
             </button>
             </Link>
             </div>
       </div>

        </div>
        </section>
  </Layout>
  <Footer/>
  </>
)

export const query = graphql`
query FrontPageCarQuery {
  allCarsJson(filter: {sold: {eq: false}}) {
    nodes {
      id
      image
      title
      price
    }
  }
}
`

export default IndexPage
