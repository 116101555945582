import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <>
<section>
  <div className="container">
    <div className="row ">
      <hr className="divider-section"/>
    </div>
  </div>
</section>
    <footer className="pt-4  mb-5">
        <div className="container">
            <div className="row text-center">
                <div className="footer-address">
                    <p><strong>Garaaž</strong>
                        Saue, Eesti<br/>
                        Külastamisel palume ette helistada</p>
                </div>
                <div className="footer-email">
                    <p><strong>Kontakt</strong>
                        <a href="mailto:indrek@revive.ee">indrek@revive.ee</a>
                        <a href="tel:52 76123">52 76123</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
            </>
        )
    }
}